<template>
	<nuxt-layout name="sidebar-layout">
		<template #aside>
			<v-account-nav class="py-40" />
		</template>

		<template #main>
			<slot />
		</template>
	</nuxt-layout>
</template>

<script lang="ts" setup></script>

<style lang="scss"></style>
